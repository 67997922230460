/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:a3b12dcd-9a46-48b0-b625-78958af74835",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_yz8cuj8Jb",
    "aws_user_pools_web_client_id": "5i0b5bg7nl88t574rfnj13n4gk",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6ynbvrwcefhj7ouxyc4fo4qa6u.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2o3v4x5jjfexpieym2d3vz5uje"
};


export default awsmobile;
