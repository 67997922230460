
import { defineComponent } from 'vue'
import { signUpEmailPassword } from '@/functions/auth.ts'

export default defineComponent({
    data(){
        return {
            email: '',
            password: '',
            passwordRepeat: ''
        }
    },
    methods: {
        signup () {
            console.log("sign up yo")
            signUpEmailPassword(this.email, this.password).then(x => console.log(x))
        }
    }
})
