
import { defineComponent } from 'vue'

export default defineComponent({
    computed: {
        routename (): string {
            return (this.$route.name as string).split('.')[1]
        }
    }
})
