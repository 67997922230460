
import { defineComponent } from "vue";
import { ExerciseState } from "@/models/index"
import { trainingStore } from "@/store/trainingStore"
import { v4 as uuid } from "@lukeed/uuid"
import DurationInput from "@/components/inputs/DurationInput.vue"

export default defineComponent({
  setup(){
      return {
          training: trainingStore.getState()
      }
  },
  components: {
     DurationInput
  },
  methods: {
    addExercise() {
        let defaultDuration = "00:00:30"
     if(this.training.exercises![this.training.exercises!.length - 1]){
         defaultDuration = this.training.exercises![this.training.exercises!.length! - 1]!.exerciseDuration as string
     }
      this.training.exercises!.push({
        id: uuid(),
        displayName: "",
        exerciseDuration: defaultDuration,
        state: ExerciseState.NOTSTARTET
      });
    },
    deleteExercise(id: string) {
      const indexOfItem = this.training.exercises!.findIndex(x => x!.id === id)
      if (indexOfItem !== -1) {
        this.training.exercises!.splice(indexOfItem, 1)
      }
    },
    goToRound(){
      this.$router.push({name: "training.round"})
    },
    saveTraining(){
      console.log(this.training)
      return null
    }
  }
})
