<template>
  <div id="home">
    <div class="title">
      <h1>VLEXO</h1>
    </div>
    <div class="menu">
      <router-link to="/training"><div class="menuitem">
        Training
      </div></router-link>
      <div class="menuitem">
        <router-link to="/login">
          <div class="menuitem">
            Login
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
